import * as React from 'react';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import { Animated } from "react-animated-css";
import { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import RainbowText from 'react-rainbow-text';


function LightBulbIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z" />
        </SvgIcon>
    );
}


function getLightness() {
    return (((Math.random() * 90) + 10) / 100.0).toFixed(1)
}


export default function ProTip(props) {
    const angles = ["0", "30", "60", "90", "120", "150", "180", "210", "240", "270", "300", "330", "360"];
    const random = () => Math.floor(Math.random() * 255);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        //Implementing the setInterval method
        if (props.rave) {
            const interval = setInterval(() => {
                setVisible(!visible);
                console.log(visible, props.rave)
            }, 4000);

            //Clearing the interval
            return () => clearInterval(interval);
        }
    }, [visible, props.rave]);


    return (
        <>
            <Typography sx={{ mt: 6, mb: 0, color: 'text.secondary' }}>

                {angles.map(angle => (
                    <LightBulbIcon key={angle}
                        style={{
                            color: `rgb(${angle / 2}, ${random()}, ${angle})`,
                            transform: `rotate(${angle + (getLightness() * 100)}deg)`
                        }}
                        sx={{ mr: 2, verticalAlign: 'middle' }} />
                ))}
            </Typography>

            <Animated key={visible} animationInDuration={3000} animationIn="jello" animationOut="jello">
                <div className="text-art">
                    <RainbowText lightness={getLightness()} saturation={1}>
                        {props.rave ? `
)))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))
)))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))
))))))))))))))))))))))))<<<<**************><)))))))))))))))))))))
))))))))))))))))))))<<<>**********************><)))))))))))))))))
)))))))))))))))))<<<>***********>>>>>>***********>)))))))))))))))
)))))))))))))))<<<>*******><)))))))))))))<>>*******>)))))))))))))
)))))))))))))<<<>******><)))))))))))))))))))<>>******>)))))))))))
)))))))))))<<<>******>))))))<<<<<>>>>>><<)))))<>>*****>))))))))))
))))))))))<<<>*****>))))<<<<>**************><))<>>>*****<))))))))
)))))))))<><>*****<))<<<<>********************<))>>>****>))))))))
))))))))<>>>****>))<<<<***********************>))<>>>*****)))))))
)))))))<>>>****>))<<<>***********><<<<>>*****<))))<>>>****<))))))
)))))))>>>*****))<>>>*********<)))))))))))<><))))))>>>****>))))))
))))))<>>>****<)<>>>********>))))))))))))))))))))))<>>>****<)))))
))))))>>>****>))>>>********<)))))))))))))))))))))))<>>>****>)))))
)))))<>>>****>)<>>>********)))))))))))))))))))))))))**>****>)))))
)))))<>>*****))<>>********<)))))))))))))))))))))))))**>****>)))))
)))))<>>*****))<>>********<))))))))))))))))))))))))<>>*****>)))))
)))))<>>*****))<>>********>))))))))))))))))))))))))>>>>****<)))))
))))))>>*****<))>>*********))))))))))))))))))))))))>>>>***>))))))
))))))>>>****>))<>>********>)))))))))))<<)))))))))>>>>****<))))))
))))))<>>*****<))<>>*********<))))))<<<<<>**>))))<>>>>****)))))))
)))))))<>>****>)))<>>**********>><<<<>>*******<)<>>>>****<)))))))
))))))))>>*****>))))<>************************>>>>>>****<))))))))
)))))))))<>*****>)))))<>********************><><>>>****<)))))))))
))))))))))<>******<))))))<>**************><<<<<<>****><))))))))))
)))))))))))<>>*****>)))))))))<<>>>>>><<))<<<<<>*****>))))))))))))
)))))))))))))<>******><)))))))))))))))<<<<<<>******<)))))))))))))
)))))))))))))))<>********><<<<<<<<<<<<<<>>******><)))))))))))))))
))))))))))))))))))<************>>>************><)))))))))))))))))
))))))))))))))))))))<<*********************><))))))))))))))))))))
)))))))))))))))))))))))<<<>>*********>><<))))))))))))))))))))))))
)))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))
)))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))
            `: ""}
                    </RainbowText>
                    <RainbowText lightness={1} saturation={1}>
                        {!props.rave ? `

))))))))))<<<>*****>))))<<<<>**************><))<>>>*****<))))))))
)))))))))<><>*****<))<<<<>********************<))>>>****>))))))))
))))))))<>>>****>))<<<<***********************>))<>>>*****)))))))
)))))))<>>>****>))<<<>***********><<<<>>*****<))))<>>>****<))))))
)))))))>>>*****))<>>>*********<)))))))))))<><))))))>>>****>))))))
))))))<>>>****<)<>>>********>))))))))))))))))))))))<>>>****<)))))
))))))>>>****>))>>>********<)))))))))))))))))))))))<>>>****>)))))
)))))<>>>****>)<>>>********)))))))))))))))))))))))))**>****>)))))
)))))<>>*****))<>>********<)))))))))))))))))))))))))**>****>)))))
)))))<>>*****))<>>********<))))))))))))))))))))))))<>>*****>)))))
)))))<>>*****))<>>********>))))))))))))))))))))))))>>>>****<)))))
))))))>>*****<))>>*********))))))))))))))))))))))))>>>>***>))))))
))))))>>>****>))<>>********>)))))))))))<<)))))))))>>>>****<))))))
))))))<>>*****<))<>>*********<))))))<<<<<>**>))))<>>>>****)))))))
)))))))<>>****>)))<>>**********>><<<<>>*******<)<>>>>****<)))))))
))))))))>>*****>))))<>************************>>>>>>****<))))))))
)))))))))<>*****>)))))<>********************><><>>>****<)))))))))
))))))))))<>******<))))))<>**************><<<<<<>****><))))))))))
            `: ""}
                    </RainbowText>
                </div >
            </Animated >
        </>
    );
}
